import { mainDiv, columnsContainer, leftColumn, mainTitle, pText, rightColumn, recipesDiv, goToHomeMainDiv, goToHomeButton,
  topTitleBreadcrumbs, breadcrumbLink, breadcrumbArrow} from './{mysqlAllCategories.pageUrl}.module.css'
import React from "react"
import NavBar from "../../components/NavBar"
import { graphql } from 'gatsby'
import DishCard from "../../components/HomeDishes/DishCard"
import Footer from "../../components/Footer"
import { Helmet } from "react-helmet"
import { getImage } from "gatsby-plugin-image"
import RecipeRightBar from "../../components/RecipeRightBar/RecipeRightBar"
import compareRecipeBySearches from "../../utils/sortRecipes";
import { navigate } from 'gatsby';
import { Link } from "gatsby"

const RecipesPage = ({data}) => { 
    const categoryData = data.allMysqlAllCategories.nodes[0];
    categoryData.categoryRecipes.sort( compareRecipeBySearches );

    const goToHome = () => {
      navigate(`/`);
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{categoryData.title} - Fede Cocina</title>
                <meta name="description" content={categoryData.shortText}></meta>
                <html lang="es"/>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
                <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org/",
                    "@graph": [
                    {
                        "@type": "Organization",
                        "@id": "https://fedecocina.net/#organization",
                        "name": "Fede Cocina",
                        "url": "https://fedecocina.net/",
                        "sameAs": [
                        "https://www.facebook.com/fede.cocina.ya",
                        "https://www.instagram.com/fede.cocina.ya",
                        "https://www.youtube.com/FedeCocina"
                        ],
                        "logo": {
                        "@type": "ImageObject",
                        "@id": "https://fedecocina.net/#logo",
                        "inLanguage": "es-AR",
                        "url": "https://fedecocina.net/imagenes/logo.png",
                        "width": 800,
                        "height": 800,
                        "caption": "Fede Cocina"
                        },
                        "image": {
                        "@id": "https://fedecocina.net/#logo"
                        }
                    },
                    {
                        "@type": "WebSite",
                        "@id": "https://fedecocina.net/#website",
                        "url": "https://fedecocina.net/",
                        "name": "Fede Cocina",
                        "description": "Las recetas más fáciles y rapidas para que prepares en tu casa.",
                        "publisher": {
                        "@id": "https://fedecocina.net/#organization"
                        },
                        "potentialAction": [
                        {
                            "@type": "SearchAction",
                            "target": "https://fedecocina.net/busqueda-receta?search={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                        ],
                        "inLanguage": "es-AR"
                    },
                    {
                        "@type": "BreadcrumbList",
                        "@id": "https://fedecocina.net/recetas/` + categoryData.pageUrl + `/#breadcrumb",
                        "itemListElement": [
                          {
                            "@type": "ListItem",
                            "position": 1,
                            "item": {
                              "@type": "WebPage",
                              "@id": "https://fedecocina.net/",
                              "url": "https://fedecocina.net/",
                              "name": "Home"
                            }
                          },
                          {
                            "@type": "ListItem",
                            "position": 2,
                            "item": {
                              "@type": "WebPage",
                              "@id": "https://fedecocina.net/recetas/todas-las-recetas/",
                              "url": "https://fedecocina.net/recetas/todas-las-recetas/",
                              "name": "Todas las Recetas"
                            }
                          },
                          {
                            "@type": "ListItem",
                            "position": 2,
                            "item": {
                              "@type": "WebPage",
                              "@id": "https://fedecocina.net/recetas/` + categoryData.pageUrl + `/",
                              "url": "https://fedecocina.net/recetas/` + categoryData.pageUrl + `/",
                              "name": "` + categoryData.title + `"
                            }
                          }
                        ]
                    }
                    ]
                }`}
                </script>
            </Helmet>
            <NavBar></NavBar>
            <div className={mainDiv}>
                <div className={columnsContainer}>
                    <div className={leftColumn}>
                        <p className={topTitleBreadcrumbs}>
                          <Link className={breadcrumbLink} to={`/`}>Inicio</Link>
                          <span className={breadcrumbArrow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path></svg> </span>
                          <Link className={breadcrumbLink} to={`/todas-las-recetas/`}>Recetas</Link>
                        </p>
                        <h1 className={mainTitle}>{categoryData.title}</h1>
                        <div className={pText} dangerouslySetInnerHTML={{__html: categoryData.text}}></div>
                        <div className={recipesDiv}>
                            { categoryData.categoryRecipes.map((item) => {
                            const image = getImage(item.image.childImageSharp);
                            return (
                                <DishCard key={item.id} pageUrl={item.pageUrl} image={image} name={item.shortName} shortText={item.introText} category={item.category} rate={item.rate} slimMargin={true}></DishCard>
                            )
                            })
                            }
                        </div>
                        <div className={goToHomeMainDiv} onClick={() => goToHome()}>¿No encontraste lo que querías? Volvé al inicio haciendo clic acá y aprende las recetas más buscadas de internet.</div>
                        <button className={goToHomeButton} onClick={() => goToHome()}>Ir al Inicio</button>
                    </div>
                    <div className={rightColumn}>
                        <RecipeRightBar></RecipeRightBar>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export const query = graphql`
query($pageUrl: String) {
    allMysqlAllCategories(filter: {pageUrl: {eq: $pageUrl}}) {
      nodes {
        title
        shortText
        text
        pageUrl
        categoryRecipes {
            id
            image {
                childImageSharp {
                  gatsbyImageData(width: 400, placeholder: BLURRED)
                }
            }
            shortName
            introText
            category
            pageUrl
            avgMonthlySearches
        }
      }
    }
  }
`

export default RecipesPage
