// extracted by mini-css-extract-plugin
export var mainDiv = "{mysqlAllCategories-pageUrl}-module--mainDiv--1BhMd";
export var mainTitle = "{mysqlAllCategories-pageUrl}-module--mainTitle--2O7tG";
export var columnsContainer = "{mysqlAllCategories-pageUrl}-module--columnsContainer--1gJv3";
export var leftColumn = "{mysqlAllCategories-pageUrl}-module--leftColumn--3tbDG";
export var rightColumn = "{mysqlAllCategories-pageUrl}-module--rightColumn--WfjbL";
export var pText = "{mysqlAllCategories-pageUrl}-module--pText--2ekbQ";
export var recipesDiv = "{mysqlAllCategories-pageUrl}-module--recipesDiv--uTJUO";
export var goToHomeMainDiv = "{mysqlAllCategories-pageUrl}-module--goToHomeMainDiv--13h3S";
export var goToHomeButton = "{mysqlAllCategories-pageUrl}-module--goToHomeButton--3W9EA";
export var topTitleBreadcrumbs = "{mysqlAllCategories-pageUrl}-module--topTitleBreadcrumbs--2wENd";
export var breadcrumbLink = "{mysqlAllCategories-pageUrl}-module--breadcrumbLink--285lI";
export var breadcrumbArrow = "{mysqlAllCategories-pageUrl}-module--breadcrumbArrow--2-JPL";